import ManagedDashboard from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ManagedDashboard.vue";
import ManagedApache from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Apache/ManagedApache.vue";
import ManagedNginx from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Nginx/ManagedNginx.vue";
import ManagedCronjobs from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Cronjobs/ManagedCronjobs.vue";
import ManagedProfiler from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Profiler/ManagedProfiler.vue";
import ManagedSsh from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Ssh/ManagedSsh.vue";
import ManagedSupervisor from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Supervisor/ManagedSupervisor.vue";
import ManagedTomcat from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Tomcat/ManagedTomcat.vue";
import ManagedVarnishSsl from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/VarnishSSL/ManagedVarnishSsl.vue";
import ManagedMemcached from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/CacheAndQueue/Memcached/ManagedMemcached.vue";
import ManagedRabbitMq from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/CacheAndQueue/RabbitMq/ManagedRabbitMq.vue";
import ManagedRedis from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/CacheAndQueue/Redis/ManagedRedis.vue";
import ManagedElasticsearch from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/Elasticsearch/ManagedElasticsearch.vue";
import ManagedMysql from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/MySQL/ManagedMysql.vue";
import ManagedOpenSearch from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/OpenSearch/ManagedOpenSearch.vue";
import ManagedSolr from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/Solr/ManagedSolr.vue";
import ManagedSphinx from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/DatabaseAndSearch/Sphinx/ManagedSphinx.vue";
import ManagedBackups from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/Backups/ManagedBackups.vue";
import ManagedFirewall from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/Firewall/ManagedFirewall.vue";
import ManagedProtection from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/Protection/ManagedProtection.vue";
import ManagedShopSecurity from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/ShopSecurity/ManagedShopSecurity.vue";
import ManagedShopSecurityReport from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/SecurityAndBackups/ShopSecurity/ShopSecurityReport.vue";
import ManagedClusterControl from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ManagedClusterControl.vue";
import ManagedDns from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Dns/ManagedDns.vue";
import ManagedDnsShowZone from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Dns/ManagedDnsShowZone.vue";
import ManagedEmail from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/EMail/MailServer/ManagedEmail.vue";
import ManagedEmailDomainDetails from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/EMail/MailServer/ManagedEmailDomainDetails.vue";
import ManagedPostfix from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/EMail/Postfix/ManagedPostfix.vue";
import ManagedFtp from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Webserver/Ftp/ManagedFtp.vue";
import ManagedNodejs from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Nodejs/ManagedNodejs.vue";
import ManagedPackageUpdates from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/PackageUpdates/ManagedPackageUpdates.vue";
import ManagedShopPerformance from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ShopPerformance/ManagedShopPerformance.vue";
import ManagedShopPerformanceReport from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/ShopPerformance/ShopPerformanceReport.vue";
import ManagedMonitoring from "@development/cluster-center/src/components/ManagedCenter/ManagedPartials/Monitoring/ManagedMonitoring.vue";

export default [{
        path: "/clusters/:clusterId/managed",
        name: 'managed-cluster',
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_dashboard', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedDashboard
        }
    }, {
    path: "/clusters/:clusterId/servers/:serverId/managed",
    name: 'managed-root',
    children: [{
        path: '',
        name: "managed-dashboard",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_dashboard', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedDashboard
        }
    }, {
        path: 'apache',
        name: "managed-apache",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_apache', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedApache
        },
        children: [{
            path: 'domains/create',
            name: "managed-apache-new-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_apache', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'domains/:hostname',
            name: "managed-apache-edit-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_apache', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'logs/access',
            name: "managed-apache-logs-access",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_apache', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'logs/access/:host',
            name: "managed-apache-logs-access-selected",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_apache', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'logs/error',
            name: "managed-apache-logs-error",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_apache', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'logs/error/:host',
            name: "managed-apache-logs-error-selected",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_apache', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'redirects/create',
            name: "managed-apache-create-redirect",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_apache', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'redirects/:hostname',
            name: "managed-apache-edit-redirect",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_apache', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'authentications/create',
            name: "managed-apache-create-authentication",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_apache', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedApache
            }
        }, {
            path: 'authentications/:hostname',
            name: "managed-apache-edit-authentication",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_apache', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedApache
            }
        }]
    }, {
        path: 'nginx',
        name: "managed-nginx",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_nginx', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedNginx
        },
        children: [{
            path: 'domains/create',
            name: "managed-nginx-new-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_nginx', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'domains/:hostname',
            name: "managed-nginx-edit-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_nginx', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'logs/access',
            name: "managed-nginx-logs-access",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_nginx', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'logs/access/:host',
            name: "managed-nginx-logs-access-selected",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_nginx', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'logs/error',
            name: "managed-nginx-logs-error",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_nginx', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'logs/error/:host',
            name: "managed-nginx-logs-error-selected",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_nginx', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'redirects/create',
            name: "managed-nginx-create-redirect",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_nginx', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'redirects/:hostname',
            name: "managed-nginx-edit-redirect",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_nginx', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedNginx
            }
        }, {
            path: 'config',
            name: "managed-nginx-config",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_nginx', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedNginx
            }
        }]
    }, {
        path: 'cronjobs',
        name: "managed-cronjobs",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_cronjobs', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedCronjobs
        }
    }, {
        path: 'profiler',
        name: "managed-profiler",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_profiler', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedProfiler
        }
    }, {
        path: 'ssh',
        name: "managed-ssh",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_ssh', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedSsh
        },
        children: [{
            path: 'key/create',
            name: "managed-ssh-new-key",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_ssh', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedSsh
            }
        }]
    }, {
        path: 'supervisor',
        name: "managed-supervisor",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_supervisor', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedSupervisor
        },
        children: [{
            path: 'logs',
            name: "managed-supervisor-logs",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_supervisor', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedSupervisor
            }
        }]
    }, {
        path: 'tomcat',
        name: "managed-tomcat",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_tomcat', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedTomcat
        }
    }, {
        path: 'varnish-ssl',
        name: "managed-varnish-ssl",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_varnish', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedVarnishSsl
        },
        children: [{
            path: 'config',
            name: "managed-varnish-ssl-config",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_varnish', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedVarnishSsl
            }
        }, {
            path: 'logs',
            name: "managed-varnish-ssl-logs",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_varnish', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedVarnishSsl
            }
        }, {
            path: 'startparameter',
            name: "managed-varnish-ssl-startparameter",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_varnish', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedVarnishSsl
            }
        }]
    }, {
        path: 'memcached',
        name: "managed-memcached",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_memcached', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedMemcached
        }
    }, {
        path: 'rabbit-mq',
        name: "managed-rabbit-mq",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_rabbit_mq', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedRabbitMq
        }
    }, {
        path: 'redis',
        name: "managed-redis",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_redis', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedRedis
        }
    }, {
        path: 'elasticsearch',
        name: "managed-elasticsearch",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_elasticsearch', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedElasticsearch
        }
    }, {
        path: 'mysql',
        name: "managed-mysql",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_mysql', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedMysql
        }
    }, {
        path: 'open-search',
        name: "managed-open-search",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_opensearch', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedOpenSearch
        }
    }, {
        path: 'solr',
        name: "managed-solr",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_solr', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedSolr
        }
    }, {
        path: 'sphinx',
        name: "managed-sphinx",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_sphinx', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedSphinx
        }
    }, {
        path: 'backups',
        name: "managed-backups",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_backups', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedBackups
        }
    }, {
        path: 'firewall',
        name: "managed-firewall",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_firewall', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedFirewall
        }
    }, {
        path: 'protection',
        name: "managed-protection",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_protection', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedProtection
        }
    }, {
        path: 'shop-security',
        name: "managed-shop-security",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
          middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_shop_security', {number: payload?.cluster?.number})
        },
        props: {
          activeComponent: ManagedShopSecurity
        },
        children: [{
            path: 'report/:reportId/',
            name: 'managed-shopsecurity-report',
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_shop_security_report', {number: payload?.cluster?.number, id: payload?.report?.id})
            },
            props: {
                activeComponent: ManagedShopSecurityReport
            }
        }]
    }, {
        path: 'cluster-control',
        name: "managed-cluster-control",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_cluster_control', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedClusterControl
        }
    }, {
        path: 'dns',
        name: "managed-dns",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_dns', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedDns
        },
        children: [{
            path: 'zones/:zoneId/modus/:mode',
            name: "managed-dns-zone",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_dns_zone', {number: payload?.cluster?.number, zone: payload?.zone?.name})
            },
            props: {
                activeComponent: ManagedDnsShowZone
            }
        }]
    }, {
        path: 'email',
        name: "managed-email",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_email', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedEmail
        },
        children: [{
            path: 'logs',
            name: "managed-email-logs",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_email', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedEmail
            }
        }, {
            path: 'domains/create',
            name: "managed-email-new-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_email', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedEmail
            }
        }, {
            path: 'domains/:domainId/verification',
            name: "managed-email-verification-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_email', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedEmail
            }
        }, {
            path: 'domains/:domainId',
            name: "managed-email-edit-domain",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_email_details', {number: payload?.cluster?.number, domain: payload?.domain?.domain})
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }, {
            path: 'domains/:domainId/mailboxes/create',
            name: "managed-email-edit-domain-mailboxes-create",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_email_details', {number: payload?.cluster?.number, domain: payload?.domain?.domain})
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }, {
            path: 'domains/:domainId/mailboxes/:mailboxId',
            name: "managed-email-edit-domain-mailboxes-edit",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_email_details', {number: payload?.cluster?.number, domain: payload?.domain?.domain})
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }, {
            path: 'domains/:domainId/aliases/create',
            name: "managed-email-edit-domain-aliases-create",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_email_details', {number: payload?.cluster?.number, domain: payload?.domain?.domain})
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }, {
            path: 'domains/:domainId/aliases/:aliasId',
            name: "managed-email-edit-domain-aliases-edit",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_email_details', {number: payload?.cluster?.number, domain: payload?.domain?.domain})
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }, {
            path: 'domains/:domainId/bcc/create',
            name: "managed-email-edit-domain-bcc-create",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_email_details', {number: payload?.cluster?.number, domain: payload?.domain?.domain})
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }, {
            path: 'domains/:domainId/bcc/:bccId',
            name: "managed-email-edit-domain-bcc-edit",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_email_details', {number: payload?.cluster?.number, domain: payload?.domain?.domain})
            },
            props: {
                activeComponent: ManagedEmailDomainDetails
            }
        }]
    }, {
        path: 'postfix',
        name: "managed-postfix",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_postfix', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedPostfix
        },
        children: [{
            path: 'logs',
            name: "managed-postfix-logs",
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_postfix', {number: payload?.cluster?.number})
            },
            props: {
                activeComponent: ManagedPostfix
            }
        }]
    }, {
        path: 'ftp',
        name: "managed-ftp",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_ftp', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedFtp
        }
    }, {
        path: 'nodejs',
        name: "managed-nodejs",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_node', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedNodejs
        }
    }, {
        path: 'package-updates',
        name: "managed-package-updates",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_package_updates', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedPackageUpdates
        }
    }, {
        path: 'shop-performance',
        name: "managed-shop-performance",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_shop_performance', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedShopPerformance
        },
        children: [{
            path: 'report/:reportId/',
            name: 'managed-shopperformance-report',
            component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
            meta: {
              middleware: ['authenticated'],
                title: (i18n, payload) => i18n.t('app.page_title.managed_shop_performance_report', {number: payload?.cluster?.number, id: payload?.report?.id})
            },
            props: {
              activeComponent: ManagedShopPerformanceReport
            }
        }]
    }, {
        path: 'monitoring',
        name: "managed-monitoring",
        component: () => import(/* webpackChunkName: "managed" */ "@/pages/Managed/ManagedCenterPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: (i18n, payload) => i18n.t('app.page_title.managed_monitoring', {number: payload?.cluster?.number})
        },
        props: {
            activeComponent: ManagedMonitoring
        }
    }]
}];
