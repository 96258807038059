import { ref } from 'vue';
import { browsers as supportedBrowsers } from '@/config/supportedBrowsers.js';

export function browserSupport() {
    const isSupported = ref(true);

    function getBrowserInfo() {
        const userAgent = navigator.userAgent;
        let match;
        
        if (/MSIE \d|Trident.*rv:/.test(userAgent)) {
            return { name: 'Internet Explorer', version: 0 };
        }
        
        const isSafari = /Version\/\d+\.\d+(\.\d+)? Safari\/\d+/.test(userAgent) && !/Chrome|Chromium/.test(userAgent);
                
        if (isSafari) {
            match = userAgent.match(/Version\/(\d+)\.\d+(\.\d+)?\s(Safari)/);   
        } else {
            match = userAgent.match(/(Chrome|Firefox|Safari|Edge|Opera)\/(\d+)/);      
        }      
        
        if (match) {
            return {
                name: isSafari ? match[3] : match[1],
                version: isSafari ? parseInt(match[1]) : parseInt(match[2]),
            };
        }

        return null;
    }

    function checkBrowserSupport() {
        const browserInfo = getBrowserInfo();        

        if (browserInfo) {
            const { name, version } = browserInfo;
            
            if (name === 'Internet Explorer') {
                isSupported.value = false;
                return;
            }

            const minimumVersion = supportedBrowsers[name];

            if (minimumVersion && version < minimumVersion) {
                isSupported.value = false;
            }

        } else {
            isSupported.value = true;
        }
    }

    checkBrowserSupport();

    return {
        isSupported,
    };
}
