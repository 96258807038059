<template>
    <MoleculeModal class="supportModal" v-model="display" size="lg">
        <template #modal-headline>
            {{ $t('app.support_modal.title') }}
        </template>
        <p v-html="$t('app.support_modal.text')" />
        <div class="d-flex flex-row mb-2">
            <div class="col-auto d-flex align-items-center me-2">
                <AtomIcon name="check-circle-line"></AtomIcon>
            </div>
            <div class="col">
                <p class="mb-0">{{ $t('app.support_modal.hint_1') }}</p>
            </div>
        </div>
        <div class="d-flex flex-row mb-2">
            <div class="col-auto d-flex align-items-center me-2">
                <AtomIcon name="check-circle-line"></AtomIcon>
            </div>
            <div class="col">
                <p class="mb-0">{{ $t('app.support_modal.hint_2') }}</p>
            </div>
        </div>
        <div class="d-flex flex-row mb-2">
            <div class="col-auto d-flex align-items-center me-2">
                <AtomIcon name="check-circle-line"></AtomIcon>
            </div>
            <div class="col">
                <p class="mb-0">{{ $t('app.support_modal.hint_3') }}</p>
            </div>
        </div>
        <p v-if="showDownloads">{{ $t('app.support_modal.text_2') }}</p>
        <div v-if="showDownloads" class="d-flex flex-row browserDownloads mt-2">
            <div v-if="isBrowserAvailable('edge')" class="col d-flex flex-column justify-content-center align-items-center">
                <AtomImage link="https://www.microsoft.com/de-de/edge/download" target="_blank" :src="logo('edge')" :size="80"></AtomImage>
                <AtomTextLink rel="external nofollow" target="_blank" href="https://www.microsoft.com/de-de/edge/download">
                    Edge
                </AtomTextLink>
            </div>
            <div v-if="isBrowserAvailable('firefox')" class="col d-flex flex-column justify-content-center align-items-center">
                <AtomImage link="https://www.mozilla.org/de/firefox/new/" target="_blank" :src="logo('firefox')" :size="80"></AtomImage>
                <AtomTextLink rel="external nofollow" target="_blank" href="https://www.mozilla.org/de/firefox/new/">
                    Firefox
                </AtomTextLink>
            </div>
            <div v-if="isBrowserAvailable('safari')" class="col d-flex flex-column justify-content-center align-items-center">
                <AtomImage link="https://www.apple.com/de/safari/" target="_blank" :src="logo('safari')" :size="80"></AtomImage>
                <AtomTextLink rel="external nofollow" target="_blank" href="https://www.apple.com/de/safari/">
                    Safari
                </AtomTextLink>
            </div>
            <div v-if="isBrowserAvailable('chrome')" class="col d-flex flex-column justify-content-center align-items-center">
                <AtomImage link="https://www.google.com/intl/de/chrome/" target="_blank" :src="logo('chrome')" :size="80"></AtomImage>
                <AtomTextLink rel="external nofollow" target="_blank" href="https://www.google.com/intl/de/chrome/">
                    Chrome
                </AtomTextLink>
            </div>
        </div>
    </MoleculeModal>
</template>
<script setup>
    import {computed, ref} from "vue";
    import logoFirefox from "@/assets/images/browserSVG/firefox-logo.svg";
    import logoChrome from "@/assets/images/browserSVG/chrome-logo.svg";
    import logoSafari from "@/assets/images/browserSVG/safari-logo.svg";
    import logoEdge from "@/assets/images/browserSVG/edge-logo.svg";

    const showDownloads = ref(true);

    const getOS = () => {
        const userAgent = navigator.userAgent.toLowerCase();

        if (userAgent.includes("win")) return "windows";
        if (userAgent.includes("mac")) return "macos";
        if (userAgent.includes("linux")) return "linux";
        
        showDownloads.value = false;
        return "unknown";
    };

    const os = computed(() => getOS());

    const browserAvailability = {
        windows: ["edge", "chrome", "firefox"],
        macos: ["safari", "chrome", "firefox"],
        linux: ["chrome", "firefox"]
    };

    const isBrowserAvailable = (browser) => {
        return browserAvailability[os.value]?.includes(browser);
    };

    const props = defineProps({
        show: Boolean
    });

    const emit = defineEmits(['close']);

    const logo = (name) => ({
        edge: logoEdge,
        chrome: logoChrome,
        firefox: logoFirefox,
        safari: logoSafari
    }[name]);

    const display = computed({
        get: () => props.show,
        set: () => emit('close')
    });

</script>