/* eslint-disable no-useless-escape */

const redirects = [
    {from: '^\/invoice\/public\#(token\/.*)$', to: {name: 'invoices-public-details'}, params: [{name: 'token', separator: '/'}]},
];

export function getRedirect(to) {
    let result = false;

    redirects.forEach(redirect => {
        let regex = new RegExp(redirect.from, 'g');

        if (regex.test(to.fullPath)) {
            if (result) {
                return;
            }

            let params = {};

            redirect.params.forEach(p => {
                let regex = new RegExp(p.name + p.separator + '(.*)', 'g');
                let result = regex.exec(to.fullPath);

                if (result) {
                    params[p.name] = result[1];
                }
            });

            result = {name: redirect.to.name, params: params, replace: true};
        }
    });

    return result;
}